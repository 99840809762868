'use client';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useRouter } from 'next/navigation';
import { Events, scrollSpy, scroller } from 'react-scroll';
import HelpBot from '@/components/ui/helpbot';
import Image from 'next/image';
import VideoModal from '@/components/ui/video-popup';
const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [organization, setOrganization] = useState('');
    const [knowAboutUs, setKnowAboutUs] = useState('Social Media');
    const [formState, setFormState] = useState<FormState>({ name: '', email: '', mobile: '', isAgreed: false, });
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [isSending, setIsSending] = useState(false);
    const router = useRouter();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };
    const handleOrganizationChange = (event) => {
        setOrganization(event.target.value);
    };
    const handleKnowAboutUsChange = (e) => {
        setKnowAboutUs(e.target.value);
    };
    const handleAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setFormState((prev) => ({
            ...prev,
            isAgreed: isChecked,
        }));
        if (isChecked) {
            setErrors((prev) => ({ ...prev, isAgreed: '' }));
        }
    };
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        const newErrors: Record<string, string> = {};
        // Validation for Name
        if (!formState.name.trim()) {
          newErrors.name = 'Name is required.';
        }
        // Validation for Email
        if (!formState.email.trim()) {
          newErrors.email = 'Email is required.';
        } else if (
          !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formState.email)
        ) {
          newErrors.email = 'Enter a valid email address.';
        }
        // Validation for Mobile
        if (!formState.mobile.trim()) {
          newErrors.mobile = 'Contact number is required.';
        } else if (!/^\+?[0-9()-\s]+$/.test(formState.mobile)) {
          newErrors.mobile = 'Enter a valid contact number.';
        }
        // Validation for Terms Agreement
        if (!formState.isAgreed) {
          newErrors.isAgreed = 'You must agree to the terms and conditions.';
        }
        // If there are errors, set them and stop form submission
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
        }
        // Clear errors and proceed to submit the form
        setErrors({});
        setIsSending(true);
        try {
          const response = await fetch('/api/landingPage', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: formState.name,
              email: formState.email,
              mobile: formState.mobile,
              message,
              organization,
              knowAboutUs,
            }),
          });
          const result = await response.json();
          if (response.ok) {
            // Reset form state and show success message
            setFormState({
              name: '',
              email: '',
              mobile: '',
              isAgreed: false,
            });
            setMessage('');
            setOrganization('');
            setKnowAboutUs('Social Media');
            toast.success(result.message || 'Form submitted successfully!');
          } else {
            // Handle API errors
            toast.error(result.message || 'Failed to submit the form. Please try again.');
          }
        } catch (error) {
          console.error('Error submitting form:', error);
          toast.error('Something went wrong. Please try again later.');
        } finally {
          setIsSending(false);
        }
      };
    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    }, [isOpen]);
    useEffect(() => {
        Events.scrollEvent.register('begin', function () {
            console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
            console.log("end", arguments);
        });
        scrollSpy.update();
        return () => {
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        };
    }, []);
    const scrollToDemo = () => {
        scroller.scrollTo('target-demo', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -window.innerHeight * 0.14
        });
    };
    const scrollToFeatures = () => {
        scroller.scrollTo('target-features', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -window.innerHeight * 0.14
        });
    };
    const scrollToContactUs = () => {
        scroller.scrollTo('target-contact', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -window.innerHeight * 0.14
        });
    };
    const features = [
        {
            color: '#00a4eb',
            image: '/feature1.svg',
            title: 'Custom Private Bots',
            description: 'Create personalized bots by uploading your own content. Whether it\'s company protocols, educational materials, or specialized information, ensure your bots provide accurate and relevant responses specific to your requirements.'
        },
        {
            color: '#735df9',
            image: '/feature2.svg',
            title: 'Chatbots',
            description: 'Automate customer interactions with intelligent, responsive chatbots that offer instant support and engage users effectively.'
        },
        {
            color: '#f4c33b',
            image: '/feature3.svg',
            title: 'Invite Others to Your Bots',
            description: 'Enhance collaboration by inviting team members, students, or clients to interact with your private bots. Share knowledge seamlessly and improve team communication by providing access to shared resources.'
        },
        {
            color: '#19e069',
            image: '/feature4.svg',
            title: 'Workflow Automation',
            description: 'Optimize efficiency by automating routine tasks and processes, allowing your team to focus on strategic initiatives.'
        },
        {
            color: '#c53df2',
            image: '/feature5.svg',
            title: 'Create Custom Channels',
            description: 'Develop dedicated channels filled with your proprietary content. Grant access to specific groups, departments, or classes, fostering a centralized hub for information and interactive learning.'
        },
        {
            color: '#ff57af',
            image: '/feature6.svg',
            title: 'Data Insights',
            description: 'Make informed decisions with actionable analytics and reporting, turning raw data into valuable business intelligence.'
        }
    ];
    const usecase = [
        { color: "#ecfff3", title: "Education", description: "Enhance learning experiences with personalized AI assistance.", image: "/usecase1.svg", mobileimage: "/usecasemobile1.svg" },
        { color: "#f8ecff", title: "Non-Profits", description: "Streamline outreach and donor engagement.", image: "/usecase2.svg", mobileimage: "/usecasemobile2.svg" },
        { color: "#ecf3ff", title: "Small Businesses", description: "Boost productivity and customer satisfaction.", image: "/usecase3.svg", mobileimage: "/usecasemobile3.svg" },
        { color: "#fff9ec", title: "Healthcare", description: "Improve patient interactions and administrative efficiency.", image: "/usecase4.svg", mobileimage: "/usecasemobile4.svg" }
    ];
    return (
        <div>
        <div className="w-full  flex justify-center items-center flex-col">
            {/* Responsive Header */}
            <div className="w-full bg-white border-b-2 border-[#5260eb]/40 sticky top-0 right-0 z-50">
                <div className="max-w-screen-xl mx-auto px-4 h-[14vh] flex justify-between items-center">
                    {/* Logo container, always visible, with adjusted margin for spacing */}
                    <div className="flex-1 mr-4 md:mr-0 z-30 cursor-pointer" onClick={() => router.push(`/`)}>
                        <img alt="Botafy Logo" src="/landing_botafy_logo.svg" className="h-10" />
                    </div>
                    {/* Hamburger Menu (only visible on mobile) and Navigation Links */}
                    <div className="flex items-center">
                        {/* Hamburger Icon */}
                        <div className="text-3xl text-gray-800 md:hidden z-20 mr-4" onClick={() => setIsOpen(!isOpen)}>
                            &#9776;
                        </div>
                        {/* Navigation menu */}
                        <div className={`fixed inset-0 bg-white z-10 md:static md:bg-transparent ${isOpen ? 'flex' : 'hidden'} flex-col md:flex-row md:flex md:items-center md:gap-10 max-sm:ps-0 pt-28 md:pt-0 pl-6 md:pl-0 overflow-auto`}>
                            {/* <div className="text-black text-xl font-medium hover:text-blue-500 transition duration-200 my-2 cursor-not-allowed">Pricing</div> */}
                            <div className="cursor-pointer text-black max-sm:py-4 max-sm:border-y-2 text-xl max-sm:my-0 max-sm:text-center font-medium hover:text-blue-500 transition duration-200 my-2 hover:underline hover:underline-offset-4" onClick={scrollToDemo}>Demo</div>
                            <div className="cursor-pointer text-black max-sm:py-4 max-sm:border-b-2 text-xl max-sm:my-0 max-sm:text-center font-medium hover:text-blue-500 transition duration-200 my-2 hover:underline hover:underline-offset-4" onClick={scrollToContactUs}>Contact Us</div>
                            <div className="cursor-pointer text-black text-xl max-sm:border-b-2 font-medium max-sm:justify-center hover:text-blue-500 transition duration-200 py-4 inline-flex sm:hidden" onClick={() => router.push(`/login`)}>SignUp/Login</div>
                            <button
                                onClick={() => router.push(`/login`)}
                                className="h-auto hidden px-6 py-2 rounded-full border-2 border-black text-black text-lg font-medium font-['DM Sans'] leading-none cursor-pointer transition duration-200 hover:bg-black hover:text-white hover:shadow-md md:inline-flex items-center justify-center"
                            >
                                SignUp/Login
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Hero Section */}
            <div className="relative flex justify-center items-center flex-col lg:flex-row gap-8 max-w-screen-xl mx-auto py-16">
                {/* Right Side (Image) */}
                <div className="lg:w-1/2 order-1 lg:order-2">
                    <img alt="AI-powered automation illustration" className="w-full h-auto" src="/heroImage.svg" />
                </div>
                {/* Left Side (Content) */}
                <div className="flex flex-col justify-center items-start gap-8 lg:w-1/2 order-2 lg:order-1 px-4 md:px-8 lg:px-0">
                    <div className="text-3xl lg:text-4xl font-semibold text-gray-800 leading-tight">
                        Revolutionize Your Business with AI-Powered Automation
                    </div>
                    <div className="text-xl text-gray-700">
                        Unleash the potential of artificial intelligence to transform your business operations. Botafy brings advanced AI-powered automation to streamline processes, enhance customer engagement, and accelerate growth. Join the revolution and make technology work for you.
                    </div>
                    <div className="flex gap-4">
                        <button className="hidden cursor-not-allowed px-8 py-3 bg-[#5260eb] text-white text-lg rounded-full hover:bg-[#3e50c3] active:bg-[#2c3c9c] transition duration-200">
                            Free Trial
                        </button>
                        <button className="cursor-pointer px-8 py-3 bg-[#1bb560] text-white text-lg rounded-full hover:bg-[#169c4b] active:bg-[#118538] transition duration-200" onClick={scrollToFeatures}>
                            Explore Features
                        </button>
                    </div>
                </div>
            </div>
            {/* Why Choose Section */}
            <div className="w-full bg-[#5260eb] py-16">
                <div className="relative max-w-screen-xl mx-auto flex flex-col lg:flex-row gap-8">
                    {/* Left Side (Content) */}
                    <div className="flex flex-col justify-center items-start gap-8 lg:w-1/2 order-2 lg:order-1 px-4 md:px-8 lg:px-0">
                        <div className="text-3xl lg:text-4xl font-semibold text-white leading-tight">
                            Why Choose Botafy for Your Business Transformation?
                        </div>
                        <div className="text-xl text-white">
                            At Botafy, we are committed to making AI accessible, affordable, and scalable for businesses of all sizes. Our user-friendly platform eliminates complexity, empowering you to harness the power of AI without the steep learning curve. Experience seamless integration and watch your business evolve.
                        </div>
                        <button className="hidden cursor-not-allowed px-8 py-3 bg-[#1bb560] text-white text-lg rounded-full hover:bg-[#169c4b] active:bg-[#118538] transition duration-200">
                            Learn More
                        </button>
                    </div>
                    {/* Right Side (Image) */}
                    <div className="lg:w-1/2 order-1 lg:order-2">
                        <img alt="Botafy platform overview" className="w-full h-auto" src="/motoImage.svg" />
                    </div>
                </div>
            </div>
            {/* Feature Section */}
            <div className="w-full bg-white py-16" id="target-features">
                <div className="max-w-screen-xl mx-auto text-center mb-8 px-4 md:px-8">
                    <h2 className="text-[#4d4d4d] text-4xl font-semibold">
                        Features - Botafy
                    </h2>
                    <p className="text-[#4d4d4d] text-2xl font-medium mt-4">
                        AI Features Designed to Empower Your Growth
                    </p>
                    <p className="text-[#4d4d4d] text-lg font-medium">
                        Equip your business with cutting-edge AI tools tailored to your needs:
                    </p>
                </div>
                <div className="flex justify-center">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 px-14 md:px-8 max-w-screen-xl">
                        {features.map((feature, index) => (
                            <div key={index}
                                className="rounded-[20px] border-2 p-6"
                                style={{ background: `linear-gradient(to bottom, white, ${feature.color})`, borderColor: feature.color }}>
                                <div className='flex justify-center m-8'>
                                    <img alt={feature.title} className="w-36 h-[119px]" src={feature.image} />
                                </div>
                                <h3 className="text-[#4d4d4d] text-2xl font-semibold">{feature.title}</h3>
                                <p className="text-[#4d4d4d] text-lg font-normal">
                                    {feature.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="flex justify-center gap-4 mt-12">
                    <button className="hidden cursor-not-allowed px-8 py-3 bg-[#5260eb] text-white rounded-full hover:bg-[#3e50c3]">
                        Explore Features
                    </button>
                    <button className="cursor-pointer px-8 py-3 bg-[#1bb560] text-white rounded-full hover:bg-[#169c4b]" onClick={(e) => router.push(`/login`)}>
                        Get Started
                    </button>
                </div>
            </div>
            {/* Social Website Section */}
            <div className="relative bg-[#1bb560] w-full h-full flex justify-center items-center p-6">
                <div className="max-w-screen-xl w-full flex flex-col-reverse lg:flex-row justify-between items-start lg:items-center">
                    {/* Left Side Content */}
                    <div className="flex flex-col items-center text-white mb-8 lg:mb-0 lg:w-1/2 lg:items-start">
                        <h2 className="text-4xl font-semibold mb-4">
                            Your AI Tools, Wherever You Go
                        </h2>
                        <p className="text-xl font-medium leading-relaxed text-center lg:text-left">
                            Stay connected to your business anytime, anywhere. Access Botafy's powerful AI tools through our intuitive web app or on-the-go with our mobile applications. Log in online or download the app from the App Store or Google Play to manage your operations effortlessly.
                        </p>
                            {/* <button className="cursor-not-allowed w-full sm:w-[360px] h-[50px] bg-[#5260eb] text-white text-xl font-semibold rounded-[30px] flex items-center justify-center py-3 px-8">
                                Login to Web App
                            </button> */}
                            <div className="flex flex-col space-y-4 mt-4 items-center lg:items-start">
                                {/* Adjusted buttons with consistent logo size and button dimensions */}
                                <button className="cursor-not-allowed w-full sm:w-[360px] h-[50px] bg-white text-[#4d4d4d] text-xl font-semibold rounded-[30px] flex items-center justify-center py-3 px-6 gap-2">
                                    <Image src={'/SVGAppstore.svg'} alt="App Store Icon" width={20} height={20} />
                                    Coming Soon on App Store
                                </button>
                                <button className="cursor-not-allowed w-full sm:w-[360px] h-[50px] bg-white text-[#4d4d4d] text-xl font-semibold rounded-[30px] flex items-center justify-center py-3 px-6 gap-2">
                                    <Image src={'/SVGPlaystore.svg'} alt="Google Play Icon" width={15} height={15} />
                                    Coming Soon on Google Play
                                </button>
                            </div>
                        </div>
                    {/* Right Side Image */}
                    <div className="lg:w-1/2 flex justify-center items-center">
                        <img src="/globalconnect.svg" alt="Global Connect" className="w-full h-auto max-w-lg" />
                    </div>
                </div>
            </div>
            {/* Use Cases Section */}
            <div className="relative flex justify-center items-center flex-col lg:flex-row gap-8 max-w-screen-xl mx-auto py-16 px-4 md:px-8">
                <div className="w-full flex flex-col justify-center items-center gap-8">
                    <div className="text-4xl font-semibold text-[#4d4d4d]">Use Cases</div>
                    <div className="w-full flex flex-col justify-start items-center gap-8">
                        {usecase.map((usecase, index) => (
                            <div key={index}
                                className="w-full lg:w-[1000px] grid grid-cols-4 items-center rounded-full border-2 border-[#496188]/10 p-0.5 sm:p-1"
                                style={{ background: `linear-gradient(to right, ${usecase.color}, #FFF)`, borderColor: usecase.color }}>
                                <div className="col-span-1 h-full">
                                    <img alt={`${usecase.title} use case`} className="w-full h-full object-cover hidden md:block" src={usecase.image} />
                                    <img alt={`${usecase.title} use case`} className="w-full h-full object-cover block md:hidden" src={usecase.mobileimage} />
                                </div>
                                <div className="col-span-3 flex flex-col justify-center pl-4">
                                    <div className="text-[#4d4d4d] text-sm md:text-2xl font-semibold">{usecase.title}</div>
                                    <div className="text-[#4d4d4d] text-xs md:text-xl">{usecase.description}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* affordable ai section */}
            <div className="bg-[#1c274c] w-full py-16">
                <div className="flex flex-col-reverse lg:flex-row justify-center items-center max-w-screen-xl mx-auto gap-8">
                    {/* Left Side (Content) */}
                    <div className="flex flex-col justify-center items-center lg:items-start text-white w-full lg:w-1/2 px-4 md:px-8 lg:px-0">
                        <div className="text-3xl lg:text-4xl font-semibold leading-tight">Affordable AI for Every Business Size</div>
                        <div className="text-xl font-medium my-6">
                            Invest in innovation without compromising your budget. Our transparent, tiered pricing plans are designed to be inclusive, offering growth-oriented solutions for startups to enterprises. Choose the plan that fits your needs today and scale up as your business expands.
                        </div>
                        <div className="flex flex-col lg:flex-row gap-4 justify-center lg:justify-start ">
                            {/* <button className="cursor-not-allowed bg-[#5260eb] text-white text-lg font-semibold py-3 px-8 rounded-[30px]">
                                View Pricing Plans
                            </button> */}
                            <button className="cursor-pointer bg-[#1bb560] text-white text-lg font-semibold py-3 px-8 rounded-[30px]" onClick={scrollToContactUs}>
                                Contact Us
                            </button>
                        </div>
                    </div>
                    {/* Right Side (Image) */}
                    <div className="w-full lg:w-1/2 flex justify-center items-center">
                        <img alt="AI-Business" className="w-full h-auto" src="/AI-Business.svg" />
                    </div>
                </div>
            </div>
            {/* About Botafy  */}
            <div className="relative flex justify-center items-center flex-col-reverse lg:flex-row gap-8 max-w-screen-xl mx-auto py-16">
                {/* Left Side (Content) */}
                <div className="flex flex-col justify-center items-start gap-8 lg:w-1/2 order-2 lg:order-2 px-4 md:px-8 lg:px-0">
                    <div className="text-3xl lg:text-4xl font-semibold text-gray-800 leading-tight">
                        What Businesses Are Saying About Botafy
                    </div>
                    <div className="text-xl text-gray-700">
                        Don't just take our word for it. Hear from businesses like yours that have transformed their operations with Botafy. Our case studies and testimonials highlight real-world success stories, showcasing the tangible benefits of our AI solutions.
                    </div>
                    <div className="flex flex-col gap-4 items-center lg:items-start w-full">
                        <button className="cursor-not-allowed w-[360px] px-8 py-3 bg-[#5260eb] text-white text-lg rounded-full hover:bg-[#3e50c3] active:bg-[#2c3c9c] transition duration-200" >
                            Read More Testimonials
                        </button>
                        <button className="cursor-not-allowed w-[360px] px-8 py-3 bg-[#1bb560] text-white text-lg rounded-full hover:bg-[#169c4b] active:bg-[#118538] transition duration-200" >
                            Explore Case Studies
                        </button>
                    </div>
                </div>
                {/* Right Side (Image) */}
                <div className="lg:w-1/2 order-2 lg:order-1">
                    <img alt="BusinessData.svg" className="w-full h-auto" src="/BusinessData.svg" />
                </div>
            </div>
            {/* Live Demo Section */}
            <div className="w-full flex justify-center items-center flex-col max-w-screen-xl mx-auto py-16 px-4 md:px-8" id="target-demo">
                <div className="relative bg-[#5260eb] rounded-[50px] overflow-hidden w-full lg:w-[1200px] h-auto flex flex-col items-center text-center p-8">
                    <h2 className="text-white text-4xl font-semibold mb-6">See Botafy in Action </h2>
                    <p className="text-white text-xl font-medium mb-6 leading-relaxed px-4 lg:px-24">
                        Experience the Botafy difference. Watch our demo video to see our platform in action, explore comprehensive guides and resources, or schedule a live demo with our experts. Get an in-depth understanding of how our AI solutions can drive your business forward.
                    </p>
                    <div className="w-full flex justify-center mb-6">
                        <img
                            alt="Live Demo Illustration"
                            src="/liveDemo.svg"
                            className="w-[60%] lg:w-[40%] h-auto"
                        />
                    </div>
                    <div className="flex flex-col md:flex-row items-center gap-4">
                        <button className="cursor-not-allowed px-8 py-3 bg-[#1bb560] text-white text-lg rounded-full">
                            Watch Demo Video
                        </button>
                        {/* <button className="cursor-pointer px-8 py-3 bg-[#1bb560] text-white text-lg rounded-full" onClick={openModal}>
                                Watch Demo Video
                            </button>
                            <VideoModal
                                isOpen={isModalOpen}
                                onClose={closeModal}
                                videoSrc="https://botafy.blob.core.windows.net/boatfy/botafy/videos/3ea80df7-f3f6-4c8a-8d3c-aa5af2214318-myrecording.mp4"
                            /> */}
                        <button className="cursor-not-allowed px-8 py-3 bg-white text-[#5260eb] text-lg rounded-full">
                            Schedule Live Demo
                        </button>
                    </div>
                </div>
            </div>
            {/* Contact Us Section */}
            <div className="w-full max-w-4xl mx-auto px-4 py-8" id="target-contact">
                <div className="text-center mb-8">
                    <h2 className="text-4xl font-semibold text-gray-800">Start Your AI Journey Today</h2>
                    <p className="text-xl font-medium text-gray-600 mt-4">
                        Ready to embrace the future of business automation? We're here to help. Fill out our simple contact form or email us directly to connect with our team. Let's work together to unlock your business's full potential.
                    </p>
                </div>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div className="relative mb-4">
  <span className="absolute top-[10%] left-2 text-red-500">*</span>
  <input
    type="text"
    placeholder="Enter your name"
    className="w-full h-12 rounded-lg border-2 px-4 py-2 text-lg focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500"
    value={formState.name}
    onInput={(e) => {
      const input = e.target as HTMLInputElement;
      setFormState((prev) => ({ ...prev, name: input.value }));
      if (input.value.trim()) {
        setErrors((prev) => ({ ...prev, name: '' }));
      }
    }}
  />
  {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
</div>

                            <div className="relative mb-4">
                            <span className="absolute top-[10%] left-2 text-red-500">*</span>
  <input
    type="email"
    placeholder="Enter your email"
    className="w-full h-12 rounded-lg border-2 px-4 py-2 text-lg focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500"
    value={formState.email}
    onInput={(e) => {
      const input = e.target as HTMLInputElement;
      const emailValue = input.value.trim();
      setFormState((prev) => ({ ...prev, email: emailValue }));
      // Clear error if email is valid
      if (emailValue && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailValue)) {
        setErrors((prev) => ({ ...prev, email: '' }));
      }
    }}
  />
  {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
</div>
<div className="relative mb-4">
  <span className="absolute top-[10%] left-2 text-red-500">*</span>
  <input
    type="text"
    placeholder="Enter your contact number"
    className="w-full h-12 rounded-lg border-2 px-4 py-2 text-lg focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500"
    value={formState.mobile}
    onInput={(e) => {
      const input = e.target as HTMLInputElement;
      const sanitizedValue = input.value.replace(/[^0-9+\-()\s]/g, '');
      setFormState((prev) => ({ ...prev, mobile: sanitizedValue }));
      if (sanitizedValue.trim()) {
        setErrors((prev) => ({ ...prev, mobile: '' }));
      }
    }}
  />
  {errors.mobile && <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>}
</div>
                        <input
                            type="text"
                            placeholder="Enter your company or organization name"
                            className="w-full h-12 rounded-lg border-2 border-blue-200 px-4 py-2 text-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            value={organization}
                            onChange={handleOrganizationChange}
                        />
                    </div>
                    <textarea
                        placeholder="Enter the details of your inquiry"
                        className="w-full h-32 rounded-lg border-2 border-blue-200 px-4 py-2 text-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        value={message}
                        onChange={handleMessageChange}
                    ></textarea>
                    <div>
                        <p className="text-lg font-medium text-gray-700">How Did You Hear About Us?</p>
                        <div className="grid md:grid-flow-col auto-cols-max  gap-4 mt-2 w-full justify-between items-center">
                            <label className="flex items-center space-x-2">
                                <input type="radio" name="source" value="Social Media" className="h-5 w-5 focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500" defaultChecked onChange={handleKnowAboutUsChange} />
                                <span className="text-gray-700">Social Media</span>
                            </label>
                            <label className="flex items-center space-x-2">
                                <input type="radio" name="source" value="Referral" className="h-5 w-5 focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500" onChange={handleKnowAboutUsChange} />
                                <span className="text-gray-700">Referral</span>
                            </label>
                            <label className="flex items-center space-x-2">
                                <input type="radio" name="source" value="Advertisement" className="h-5 w-5 focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500" onChange={handleKnowAboutUsChange} />
                                <span className="text-gray-700">Advertisement</span>
                            </label>
                            <label className="flex items-center space-x-2">
                                <input type="radio" name="source" value="Search Engine" className="h-5 w-5 focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500" onChange={handleKnowAboutUsChange} />
                                <span className="text-gray-700">Search Engine</span>
                            </label>
                            <label className="flex items-center space-x-2">
                                <input type="radio" name="source" value="Other" className="h-5 w-5 focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500" onChange={handleKnowAboutUsChange} />
                                <span className="text-gray-700">Other</span>
                            </label>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center w-full">
  <div className="flex justify-center items-center gap-2">
    <input
      type="checkbox"
      className="mt-1 h-5 w-5 focus:outline-none focus:ring-2 border-blue-200 focus:ring-indigo-500"
      checked={formState.isAgreed}
      onChange={handleAgreementChange}
    />
    <label className="text-gray-700 text-sm sm:text-base md:text-lg">
      I agree to the
      <span onClick={()=>router.push('/terms-and-conditions')} className="underline font-medium hover:text-blue-500" > terms and conditions</span> and the
      <span onClick={()=>router.push('/privacy-policy')} className="underline font-medium hover:text-blue-500"> privacy policy</span>.
      <span className="relative top-[10%] left-1 text-red-500 text-base md:text-lg">*</span>
    </label>
  </div>
  {errors.isAgreed && <p className="text-red-500 text-sm mt-1 text-center">{errors.isAgreed}</p>}
</div>

                    <div className="flex justify-center items-center">
                        <button
                            type="submit"
                            className="self-center w-full md:w-[300px] px-6 py-3 bg-indigo-600 text-white text-lg font-semibold rounded-full transition duration-200 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            disabled={isSending}
                        >
                            {isSending ? 'Sending...' : 'Submit'}
                        </button>
                    </div>
                </form>
            </div>
            {/* Footer Section */}
            <div className="w-full bg-[#f2f2f2] py-8">
                <div className="max-w-screen-xl mx-auto px-4 md:px-8 lg:px-0">
                    {/* Info Section */}
                    <div className="text-xs scale-90 sm:scale-100 sm:text-base text-[#4d4d4d] font-medium leading-[30px]">
                        Navigate our site to learn more about what Botafy can do for you. Access important information, review our policies, and connect with us on social media to stay updated on the latest AI innovations and company news.
                    </div>
                    <div className='flex flex-col sm:flex-row-reverse items-center justify-center w-full'>
                        {/* Social Icons */}
                        <div className="mt-4 hidden justify-center items-center gap-2 lg:justify-start">
                            <img alt="facebook" src="/facebook.svg" className="w-auto h-auto cursor-not-allowed" />
                            <img alt="instagram" src="/instagram.svg" className="w-auto h-auto cursor-not-allowed" />
                            <img alt="twitter" src="/twitter.svg" className="w-auto h-auto cursor-not-allowed" />
                            <img alt="linked_in" src="/linked_in.svg" className="w-auto h-auto cursor-not-allowed" />
                        </div>
                        {/* Navigation Links */}
                        <div className="flex sm:flex-row justify-center gap-3 items-center sm:self-center">
                                <div className="cursor-pointer text-xs scale-90 sm:scale-100 sm:text-base text-[#4d4d4d] font-semibold underline" onClick={() => { router.push('/terms-and-conditions') }}>
                                    Terms and Conditions
                                </div>
                                <div className="cursor-pointer text-xs scale-90 sm:scale-100 sm:text-base text-[#4d4d4d] font-semibold underline" onClick={() => { router.push('/privacy-policy') }}>
                                    Privacy Policy
                                </div>
                            </div>
                        </div>
                    </div>
                <HelpBot/>
            </div>
        </div>
        </div>

    );
}
export default Home;
